import Loading from "./loading.tsx";
import "./failedComponent.css";

const LoadingSigoutProgressComponent = () => {
    return (
        <section className="button-dock">
            <Loading />
            <div className="caution-icon2">
                <div className="div1">탈퇴를 진행하고 있어요</div>
                <div className="recheck-account-wrapper">
                    <div className="recheck-account">
                        <div className="div2">잠시만 기다려주세요.</div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default LoadingSigoutProgressComponent;
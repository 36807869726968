import { analytics} from '../fbInstance';
import { logEvent } from "firebase/analytics";
import { useTranslation } from "react-i18next";
import '../i18n/i18n';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import "./login.css";

const SocialLogin = () => {

  logEvent(analytics, 'web_impression_login', {name: 'before_web_impression_login'});

  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const redirect_uri = process.env.REACT_APP_REDIRECT_URL //Redirect URI

  const [userData, setUserData] = useState(null);

  function handleKakaoLogin() {
    const Rest_api_key = process.env.REACT_APP_KAKAO_APP_KEY //REST API KEY

    var kakaoRedirectUri = `${redirect_uri}kakaoLoginLoading`

    // oauth 요청 URL
    const kakaoURL = `https://kauth.kakao.com/oauth/authorize?client_id=${Rest_api_key}&redirect_uri=${kakaoRedirectUri}&response_type=code`
    window.location.href = kakaoURL
  }

  function handleLineLogin() {
    var lineRedirectUri = `${redirect_uri}lineLoginLoading`
    var uuid = uuidv4();
    const LineURL = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${process.env.REACT_APP_LINE_CLIENT_ID}&redirect_uri=${lineRedirectUri}&state=${uuid}&scope=profile`

    window.location.href = LineURL
  }

  function handleGoogleLogin() {
    var googleURL = "https://accounts.google.com/o/oauth2/v2/auth?" +
      `client_id=${process.env.REACT_APP_GOOGLE_CLIENT_ID}&` +
      `redirect_uri=${process.env.REACT_APP_GOOGLE_REDIRECT_PROD_URI}&` +    //REACT_APP_GOOGLE_REDIRECT_PROD_URI
      "response_type=token&" +
      "prompt=select_account&" +
      "scope=https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile";

    window.location.href = googleURL;
  }

  function handleAppleLogin() {
    navigate(
      `${process.env.REACT_APP_BASE_URL}/auth/appleLoginLoading`,
    )
    // var AUTHURL = process.env.REACT_APP_APPLE_AUTHURL;
    // var CLIENT_ID = process.env.REACT_APP_APPLE_CLIENT_ID;
    // const appleRedirectUri = process.env.REACT_APP_APPLE_REDIRECT_URL;
    // var scope = "name%20email";

    // var appleURL = `${AUTHURL}?response_type=code id_token&v=1.1.6&response_mode=form_post&client_id=${CLIENT_ID}&scope=${scope}&state=$state&redirect_uri=${appleRedirectUri}`
    // window.location.href = appleURL
  }

  function handleFaceBookLogin() {
    const Rest_api_key = process.env.REACT_APP_FACEBOOK_CLIENT_ID //REST API KEY
    var facebookRedirectUri = `${redirect_uri}facebookLoginLoading/`
    const FacebookURL = `https://www.facebook.com/v13.0/dialog/oauth?client_id=${Rest_api_key}&redirect_uri=${facebookRedirectUri}&response_type=code&scope=email&auth_type=rerequest&display=popup`
    window.location.href = FacebookURL
  }


  return (
    <>
      <div className='divMainLogin'>
        <div className="divLogin">
          <section className="parentLogin">
            <h3 className="h3Login">
              <p className="pLogin">{i18n.t('login_title').split("\n").map((line) => (<div>{line}</div>))}</p>
            </h3>
            <img
              className="img-main-logo-valentine-iconLogin"
              loading="lazy"
              alt=""
              src="/assets/img_main_logo_normal.svg"
            />
          </section>
          <section className="groupLogin">
            <div className="div1Login">{t('login_description')}</div>
            <div>
              <button className="buttonLogin" onClick={handleGoogleLogin}>
                <div className="contentLogin">
                  <img className="icon-googleLogin" alt="" src="/assets/icon_google.svg" />
                  <div className="labelLogin">{t('login_sign_in_google')}</div>
                </div>
              </button>
            </div>
            <div>
              <button className="button1Login" onClick={handleAppleLogin}>
                <div className="content1Login">
                  <img className="icon-appleLogin" alt="" src="/assets/icon_apple.svg" />
                  <div className="label1Login">{t('login_sign_in_apple')}</div>
                </div>
              </button>
            </div>
            <div>
              <button className="button2Login" onClick={handleKakaoLogin}>
                <div className="content2Login">
                  <img className="icon-kakaoLogin" alt="" src="/assets/icon_kakao.svg" />
                  <div className="label2Login">{t('login_sign_in_kakao')}</div>
                </div>
              </button>
            </div>
            <div>
              <button className="button3Login" onClick={handleFaceBookLogin}>
                <div className="content3Login">
                  <img className="icon-facebookLogin" alt="" src="/assets/icon_facebook.svg" />
                  <div className="label3Login">{t('login_sign_in_facebook')}</div>
                </div>
              </button>
            </div>
            <div>
              <button className="button4Login" onClick={handleLineLogin}>
                <div className="content4Login">
                  <img className="icon-lineLogin" alt="" src="/assets/icon_line.svg" />
                  <div className="label4Login">{t('login_sign_in_line')}</div>
                </div>
              </button>
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
export default SocialLogin
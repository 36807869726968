import Svg from "./svg/svg.tsx";
import "./loading.css";
import React from "react";

interface Props {
  size?: number;
  sw?: number;
}

function Loading({ size = 64, sw = 8 }: Props) {

  return (
    <Svg svgProps={{
      width: size,
      height: size,
      x: size,
      y: size,
      viewBox: `0 0 ${size} ${size}`,
    }}>
        <circle
          className="loading_circle"
          cx={(size / 2)}
          cy={(size / 2)}
          r={(size - sw) / 2}
          fill="none"
          stroke="#FF435A"
          strokeWidth={sw}
        />
    </Svg>
  );
}

export default Loading;
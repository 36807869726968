import UnMemberComponent from "./component/unMemberComponent.js";
import MoveLoginButton from "./component/moveLoginButton.js";
import "./component/unMemberPageFrame.css";

const EmptyUserPage = () => {

    return (
        <div className="divMainLogin">
            <UnMemberComponent />
            <MoveLoginButton />
        </div>
    )
}
export default EmptyUserPage;
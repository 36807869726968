import { useEffect } from "react";
import axios from "axios";
import { auth } from '../fbInstance';
import { signInWithCustomToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Loading from "./component/loadingProgressComponent";

const FaceBookCallback = () => {

    const navigate = useNavigate();

    const link_login_loading = `${process.env.REACT_APP_BASE_URL}/auth/loginLoading`;
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`;

    useEffect(() => {
        const params = new URL(document.location.toString()).searchParams;
        const code = params.get('code');
        const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URL}facebookLoginLoading/`;

        const GET_FIREBASE_TOKEN = process.env.REACT_APP_GET_FIREBASE_TOKEN_URL;

        console.log(code);

        axios.get(`https://graph.facebook.com/v13.0/oauth/access_token`,
            {
                params: {
                    client_id: process.env.REACT_APP_FACEBOOK_CLIENT_ID,
                    redirect_uri: REDIRECT_URI,
                    client_secret: process.env.REACT_APP_FACEBOOK_CLIENT_SECRET,
                    code: code
                }
            },
            { headers: { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" } })
            .then((response) => {
                console.log(response);

                const data = response.data;

                axios.get(`https://graph.facebook.com/me`,
                    {
                        params: {
                            access_token: data.access_token
                        }
                    })
                    .then((response) => {
                        console.log(response);

                        const data = response.data;

                        axios.post(
                            GET_FIREBASE_TOKEN,
                            {
                                socialUserType: "fb",
                                socialUserId: data.id
                            }
                        )
                            .then((response) => {
                                console.log(response.data.token);

                                signInWithCustomToken(auth, response.data.token)
                                    .then((userCredential) => {
                                        // Signed in
                                        const user = userCredential.user;
                                        //setUserData(user); // user data 설정
                                        var reloadUserInfo = user.reloadUserInfo
                                        navigate(
                                            link_login_loading,
                                            {
                                                state: {
                                                    displayName: reloadUserInfo.displayName,
                                                    userId: reloadUserInfo.localId
                                                }
                                            }
                                        )

                                        console.log("로그인 성공", userCredential);
                                        console.log("유저 데이터", reloadUserInfo);
                                        // ...
                                    })
                                    .catch((error) => {
                                        console.log("error", error);

                                        const errorCode = error.code;
                                        const errorMessage = error.message;
                                        navigate(
                                            link_login_error,
                                        )
                                        // ...
                                    });
                            })

                    })
                    .catch((error) => {
                        console.log("error 1: ", error);

                        const errorCode = error.code;
                        const errorMessage = error.message;
                        navigate(
                            link_login_error,
                        )
                    })

            })
            .catch((error) => {
                console.log("error 1: ", error);

                const errorCode = error.code;
                const errorMessage = error.message;
                navigate(
                    link_login_error,
                )
                // ...
            });
    }, [])

    return (
        <>
            <div className="divMainLogin">
                <Loading />
            </div>
        </>
    )
}
export default FaceBookCallback;
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { auth } from '../fbInstance';
import { signInWithCustomToken } from 'firebase/auth';
import axios from "axios"
import { v4 as uuidv4 } from 'uuid'

const AppleCallback = () => {

    const navigate = useNavigate();

    const userDataFunction = process.env.REACT_APP_GET_APPLE_USERDATA_URL
    const GET_FIREBASE_TOKEN = process.env.REACT_APP_GET_FIREBASE_TOKEN_URL;

    const link_login_loading = `${process.env.REACT_APP_BASE_URL}/auth/loginLoading`;
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`;
    const link_login_failed = `${process.env.REACT_APP_BASE_URL}`;

    const loginWithApple = async (e) => {
        //e.preventDefault();
        var uuid = uuidv4();
        console.log('sign in with apple');

        window.AppleID.auth.init({
            clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
            scope: 'name email',
            redirectURI: `${process.env.REACT_APP_REDIRECT_URL}appleLoginLoading`,
            state: uuid,
            usePopup: true,
        });

        try {
            const res = await window.AppleID.auth.signIn();
            console.log(res);
            const { code } = res.authorization
            console.log("코드: ", code);
            axios.post(
                userDataFunction,
                {
                    code: code,
                    beforeType: "web"
                },
                { headers: { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" } }
            )
                .then((response) => {
                    console.log("펑션후: ", response);
                    const { id } = response.data
                    axios.post(
                        GET_FIREBASE_TOKEN,
                        {
                            socialUserType: "ap",
                            socialUserId: id
                        }
                    )
                        .then((response) =>{
                            console.log(response);
                            console.log(response.data.token);
    
                            signInWithCustomToken(auth, response.data.token)
                            .then((userCredential) => {
                                // Signed in
                                const user = userCredential.user;
                                //setUserData(user); // user data 설정
                                var reloadUserInfo = user.reloadUserInfo
                                navigate(
                                    link_login_loading,
                                    {
                                        state: {
                                            displayName: reloadUserInfo.displayName,
                                            userId: reloadUserInfo.localId
                                        }
                                    }
                                )
    
                                console.log("로그인 성공", userCredential);
                                console.log("유저 데이터", reloadUserInfo);
                                // ...
                            })
                            .catch((error) => {
                                console.log("error", error);
    
                                const errorCode = error.code;
                                const errorMessage = error.message;
                                navigate(
                                    link_login_error,
                                )
                                // ...
                            });
                        })
                        .catch((error) => {
                            console.log("error 1: ", error);
            
                            const errorCode = error.code;
                            const errorMessage = error.message;
                            navigate(
                                link_login_error,
                            )
                            // ...
                        });

                })
                .catch((Error) => {
                    console.log(Error)
                    navigate(
                        link_login_error,
                    )
                })

        } catch (error) {
            console.log(error);
            navigate(
                link_login_failed,
            )
        }
    };


    useEffect(() => {

        loginWithApple()




        //라인 유저 억세스 토큰 획득
        // axios.post(
        //     `https://api.line.me/oauth2/v2.1/token`,
        //     {
        //         grant_type: grantType,
        //         code: code,
        //         redirect_uri: REDIRECT_URI,
        //         client_id: client_id,
        //         client_secret: client_secret
        //     },
        //     { headers: { "Content-type": "application/x-www-form-urlencoded;" } }
        // )
        //     .then((res) => {



        //     })
        //     .catch((Error) => {
        //         console.log(Error)
        //         navigate(
        //             link_login_error,
        //         )
        //     })

    }, [])

}

export default AppleCallback;
import { useEffect } from "react";
import axios from "axios"
import { auth } from '../fbInstance';
import { signInWithCustomToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Loading from "./component/loadingProgressComponent";

const KakaoCallback = () => {

    const navigate = useNavigate();

    const link_login_loading = `${process.env.REACT_APP_BASE_URL}/auth/loginLoading`;
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`;

    useEffect(() => {
        const params = new URL(document.location.toString()).searchParams;
        const code = params.get('code');
        const grantType = "authorization_code";
        const REST_API_KEY = `${process.env.REACT_APP_KAKAO_REST_API_KEY}`;
        const REDIRECT_URI = `${process.env.REACT_APP_REDIRECT_URL}kakaoLoginLoading`;

        const GET_FIREBASE_TOKEN = process.env.REACT_APP_GET_FIREBASE_TOKEN_URL;

        //카카오 유저 억세스 토큰 획득
        axios.post(
            `https://kauth.kakao.com/oauth/token?grant_type=${grantType}&client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&code=${code}`,
            {},
            { headers: { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" } }
        )
            .then((res) => {
                console.log(res);
                const { access_token } = res.data;
                console.log("억세스 토큰", access_token);

                axios.post(
                    `https://kapi.kakao.com/v2/user/me`,
                    {},
                    {
                        headers: {
                            Authorization: `Bearer ${access_token}`,
                            "Content-type": "application/x-www-form-urlencoded;charset=utf-8"
                        }
                    }
                )
                    .then((response) => {
                        console.log("사용자 정보", response);
                        const data = response.data;
                        //카카오 function api 파이어베이스 토큰 획득
                        axios.post(
                            GET_FIREBASE_TOKEN,
                            {
                                socialUserType: "kk",
                                socialUserId: data.id
                            }
                        )
                            .then((response) => {
                                console.log(response);
                                const { token } = response.data;
                                console.log("token", token);

                                signInWithCustomToken(auth, token)
                                    .then((userCredential) => {
                                        // Signed in
                                        const user = userCredential.user;
                                        //setUserData(user); // user data 설정
                                        var reloadUserInfo = user.reloadUserInfo
                                        navigate(
                                            link_login_loading,
                                            {
                                                state: {
                                                    displayName: reloadUserInfo.displayName,
                                                    userId: reloadUserInfo.localId
                                                }
                                            }
                                        )

                                        console.log("로그인 성공", userCredential);
                                        console.log("유저 데이터", reloadUserInfo);
                                        // ...
                                    })
                                    .catch((error) => {
                                        console.log("error", error);

                                        const errorCode = error.code;
                                        const errorMessage = error.message;
                                        navigate(
                                            link_login_error,
                                        )
                                        // ...
                                    });

                            })
                            .catch((Error) => {
                                console.log("에러")
                                console.log(Error)
                                navigate(
                                    link_login_error,
                                )
                            })

                    })
                    .catch((error) => {
                        console.log("error", error);
                        navigate(
                            link_login_error,
                        )
                    })
            })
            .catch((Error) => {
                console.log(Error)
                navigate(
                    link_login_error,
                )
            })
    }, [])

    return (
        <>
            <div className="divMainLogin">
                <Loading />
            </div>
        </>
    )
}
export default KakaoCallback;
import './App.css';
import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom';

import Login from './auth/login';
import GoogleLoginLoading from './auth/googleLoginLoading';
import KaKaoLoginLoading from './auth/kakaoLoginLoading';
import LineLoginLoading from './auth/lineLoginLoading';
import FacebookLoginLoading from './auth/facebookLoginLoading';
import AppleLoginLoading from './auth/appleLoginLoading';
import LoginLoading from './auth/loginLoading';
import EmptyUserPage from './auth/emptyUserPage';
import SigoutComplete from './auth/sigoutComplete';

import LoginMainPage from './main/loginMainPage';

import LoginErrorPage from './error/loginErrorPage';
import SigoutFailedPage from './error/sigoutFailedPage';
import NotFoundPage from './error/notFoundPage';


function App() {
  return (
    <div className="App">
        <Router>
          <Routes>
            <Route exact path="/sigout_user/auth/googleLoginLoading" element={<GoogleLoginLoading />} />
            <Route exact path="/sigout_user/auth/kakaoLoginLoading" element={<KaKaoLoginLoading />} />
            <Route exact path="/sigout_user/auth/lineLoginLoading" element={<LineLoginLoading />} />
            <Route exact path="/sigout_user/auth/facebookLoginLoading" element={<FacebookLoginLoading />} />
            <Route exact path="/sigout_user/auth/appleLoginLoading" element={<AppleLoginLoading />} />
            <Route exact path="/sigout_user/auth/loginLoading" element={<LoginLoading />} />
            <Route exact path="/sigout_user/auth/emptyUserPage" element={<EmptyUserPage />} />
            <Route exact path="/sigout_user/auth/sigoutComplete" element={<SigoutComplete />} />

            <Route exact path="/sigout_user/main/loginMainPage" element={<LoginMainPage />} />

            <Route exact path="/sigout_user/error/loginErrorPage" element={<LoginErrorPage />} />
            <Route exact path="/sigout_user/error/sigoutFailedPage" element={<SigoutFailedPage />} />
            
            <Route exact path="/sigout_user" element={<Login />} />
            <Route exact path="*" element={<NotFoundPage/>}/>
          </Routes>
        </Router>
    </div>
  );
}
/**
            <Route exact path="/main/loginMainPage" element={<LoginMainPage />} />
            <Route exact path="*" element={<NotFoundPage/>}/>
 */
export default App;

import { useNavigate } from 'react-router-dom';
import "./moveLoginButton.css";
import { useTranslation } from "react-i18next";

const MoveLoginButton = () => {

  const { t } = useTranslation();

  const navigate = useNavigate();

  const link_login_page = `${process.env.REACT_APP_BASE_URL}/`

  function moveMain(){
      navigate(
          link_login_page,
      )
  }

  return (
    <section className="button-dock1">
      <div className="container-help-text">
        <div className="text">help text here</div>
      </div>
      <button className="container-button-group">
        <div className="button-button-4">
          <div className="container">
            <div className="text-label">버튼</div>
          </div>
        </div>
        <div className="button-button-3">
          <div className="container1">
            <div className="text-label1">버튼</div>
          </div>
        </div>
        <div className="button-button-2">
          <div className="container2">
            <div className="text-label2">버튼</div>
          </div>
        </div>
        <div className="button-button-1" onClick={moveMain}>
          <div className="container3">
            <div className="text-label3">{t('login_move_main')}</div>
          </div>
        </div>
      </button>
    </section>
  );
};

export default MoveLoginButton;
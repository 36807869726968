import CompleteComponent from "./component/completeComponent.js";
import CheckSigoutButton from "./component/checkSigoutButton.js";
import "./component/unMemberPageFrame.css";

const SigoutCompletePage = () => {

    return (
        <div className="divMainLogin">
            <CompleteComponent />
            <CheckSigoutButton />
        </div>
    )
}
export default SigoutCompletePage;
import { useEffect } from "react";
import axios from "axios";
import { auth } from '../fbInstance';
import { signInWithCustomToken } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import Loading from "./component/loadingProgressComponent";

const GoogleCallback = () => {

    const navigate = useNavigate();

    const link_login_loading = `${process.env.REACT_APP_BASE_URL}/auth/loginLoading`;
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`;

    useEffect(() => {
        const parsedHash = new URLSearchParams(window.location.hash.substring(1));
        const accessToken = parsedHash.get("access_token");
        const GET_FIREBASE_TOKEN = process.env.REACT_APP_GET_FIREBASE_TOKEN_URL;
        console.log(accessToken);

        axios.get("https://www.googleapis.com/oauth2/v2/userinfo", {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        })
            .then((response) => {
                console.log(response);

                const data = response.data;
                const displayName = data.name;
                axios.post(
                    GET_FIREBASE_TOKEN,
                    {
                        mode: "live",
                        socialUserType: "gg",
                        socialUserId: data.id
                    },
                    { headers: { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" } }
                )
                    .then((response) => {
                        console.log("통신: ", response);
                        console.log(response.data.token);

                        signInWithCustomToken(auth, response.data.token)
                            .then((userCredential) => {
                                // Signed in
                                const user = userCredential.user;
                                //setUserData(user); // user data 설정
                                console.log("유저 user", user);
                                var reloadUserInfo = user.reloadUserInfo
                                navigate(
                                    link_login_loading,
                                    {
                                        state: {
                                            displayName: displayName,
                                            userId: reloadUserInfo.localId
                                        }
                                    }
                                )

                                console.log("로그인 성공", userCredential);
                                console.log("유저 데이터", reloadUserInfo);
                                // ...
                            })
                            .catch((error) => {
                                console.log("error", error);

                                const errorCode = error.code;
                                const errorMessage = error.message;
                                navigate(
                                    link_login_error,
                                )
                                // ...
                            });
                    })
                    .catch((error) => {
                        console.log("error 1: ", error);

                        const errorCode = error.code;
                        const errorMessage = error.message;
                        navigate(
                            link_login_error,
                        )
                        // ...
                    });

            })
            .catch((error) => {
                console.log("error 2: ", error);

                const errorCode = error.code;
                const errorMessage = error.message;
                navigate(
                    link_login_error,
                )
                // ...
            });
    }, [])

    return (
        <>
            <div className="divMainLogin">
                <Loading />
            </div>
        </>
    )
}
export default GoogleCallback;
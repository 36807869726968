import { analytics } from '../fbInstance';
import { logEvent } from "firebase/analytics";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import CustomUI from '../confirm/confirmSigout.js';
import axios from "axios"
import "./loginMainPage.css";
import Loading from "../auth/component/loadingSigoutProgressComponent.js";
import { useTranslation } from "react-i18next";

const LoginMainPage = () => {

    const { t } = useTranslation();

    const userDatas = useLocation();

    const [isSigoutAgree, setSigoutAgree] = useState(false);

    console.log("로그인 성공", userDatas);

    const displayName = userDatas.state?.displayName
    const userId = userDatas.state?.userId

    var name = null;
    if (displayName != null) {
        if (window.navigator.language == "ko-KR") {
            name = displayName + t('login_main_confirm_user1');
        } else {
            name = t('login_main_confirm_user1') + displayName + ",";
        }
    }

    const link_login_page = `${process.env.REACT_APP_BASE_URL}/`
    const link_login_error = `${process.env.REACT_APP_BASE_URL}/error/loginErrorPage`
    const link_sigout_complete = `${process.env.REACT_APP_BASE_URL}/auth/sigoutComplete`
    const link_sigout_failed = `${process.env.REACT_APP_BASE_URL}/error/sigoutFailedPage`

    const [isSigoutLoading, setSigoutLoading] = useState(false);

    console.log("유저Id", userId);

    const navigate = useNavigate();

    const confirmSigout = () => {
        if (!isSigoutAgree) return
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <CustomUI onClose={onClose} onPositive={requestAccountDelete} />
                );
            }
        });
    };


    const onClickSigoutAgree = () => {
        console.log("setSigoutAgree 1: " + isSigoutAgree);
        if (isSigoutAgree) {
            setSigoutAgree(false);
        } else {
            setSigoutAgree(true);
        }

        console.log("setSigoutAgree 2: " + isSigoutAgree);
    }

    function requestAccountDelete() {
        setSigoutLoading(true)
        axios.delete(
            `https://asia-northeast1-project-2545831719973302142.cloudfunctions.net/admin/delete/${userId}`,
            {
                data: {
                    mode: "live"
                }
            },
            { headers: { "Content-type": "application/x-www-form-urlencoded;charset=utf-8" } }
        ).then((result) => {
            console.log("탈퇴 정보", result);
            logEvent(analytics, 'web_withdrawal_success', { name: 'before_web_withdrawal_success' });
            navigate(
                link_sigout_complete,
            )
            setSigoutLoading(false)
        })
            .catch((error) => {
                console.log("탈퇴 실패", error);
                navigate(
                    link_sigout_failed,
                )
            })
        setSigoutLoading(false)
    }

    useEffect(() => {



    }, [])

    return (
        <>
            {isSigoutLoading ?
                <div className="divMainLogin">
                    <Loading />
                </div>

                :

                <div className="divLoginMain">
                    <section className="parentLoginMain">
                        <div tyle="float: left;">
                            <div className="div3LoginMain">
                                <div className="div1LoginMain">
                                    <p className="pLoginMain">{displayName ? name : null}</p>
                                    <p className="p1LoginMain">{t('login_main_confirm_user')}</p>
                                </div>
                            </div>
                            <div className="div2LoginMain">
                                <p className="p2LoginMain">·</p>
                                <div className="button-parentLoginMain">
                                {t('login_main_confirm_message_1')}
                                </div>
                            </div>
                            <div className="div2LoginMain">
                                <p className="p2LoginMain">·</p>
                                <div className="button-parentLoginMain">
                                {t('login_main_confirm_message_2')}
                                </div>
                            </div>
                            <div className="div2LoginMain">
                                <p className="p2LoginMain">·</p>
                                <div className="button-parentLoginMain">
                                {t('login_main_confirm_message_3')}
                                </div>
                            </div>
                        </div>

                        <div className="div2LoginMain" onClick={onClickSigoutAgree}>
                            <img
                                className="ic-radio-checkbox-off-iconLoginMain"
                                loading="lazy"
                                alt=""
                                src={isSigoutAgree ? process.env.PUBLIC_URL + '/assets/ic_radio_checkbox_on.svg' : process.env.PUBLIC_URL + '/assets/ic_radio_checkbox_off.svg'}
                            />
                            <div className="text-label4LoginMain">{t('login_main_confirm_message_confirm')}</div>
                        </div>
                    </section>
                    <div className='divBoxButtonLogin'>
                        <button className="container-button-groupLoginMain" onClick={confirmSigout}>
                            <div className="button-button-1LoginMain">
                                <div className={isSigoutAgree ? "container3OnLoginMain" : "container3LoginMain"}>
                                    <div className={isSigoutAgree ? "text-label3OnLoginMain" : "text-label3LoginMain"}>{t('login_main_delete_button')}</div>
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            }

        </>
    )
}
export default LoginMainPage